import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import { RegisterFlow } from '../../../components/views/layout'
import { Alert, LoadingOverlay, Seo } from '../../../components/common'
import { RegisterForm } from '../../../components/common/register'
import { useRegister, useLogin, useAccountTrial } from '../../../hooks'
import { isEmptyObject } from '../../../common/utils/functions'
import { Paths } from '../../../constants/structure'

import { RegisterData } from '../../../components/common/register/registerForm/registerForm.type'
import { prepareUserForAPI } from '../../../hooks/useAccountTrial/useAccountTrial.helpers'
import { registerFields } from '../../../common/utils/register'

const TrialPage = () => {
   const { formState, update } = useRegister()
   const { error, isLoading, isSuccess, register } = useAccountTrial()
   const { login } = useLogin()

   const isInvalid =
      isEmptyObject(formState) || !formState[registerFields.EMAIL]

   useEffect(() => {
      if (isInvalid) {
         navigate(Paths.REGISTER)
      }
   }, [])

   useEffect(() => {
      if (isSuccess) {
         login(
            formState[registerFields.EMAIL],
            formState[registerFields.PASSWORD]
         )
         handleSuccessfulTrial()
      }
   }, [isSuccess])

   const handleFormSubmit = (data: RegisterData) => {
      const newFormState = { ...formState, ...data }

      update(newFormState)
      register(prepareUserForAPI(newFormState))
   }

   const handleSuccessfulTrial = () => navigate(Paths.REGISTER_TRIAL_VERIFY)

   return (
      <RegisterFlow title="Step 2">
         <Seo title="Register - Step 2" />

         {!!error && <Alert message={error} />}

         {isInvalid ? (
            <LoadingOverlay />
         ) : (
            <RegisterForm isLoading={isLoading} onRegister={handleFormSubmit} />
         )}
      </RegisterFlow>
   )
}

export default TrialPage
