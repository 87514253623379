import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'

import {
   FieldError,
   Input,
   Toggle,
} from '../../../../components/common/form/fields'
import { Button } from '../../../../components/common'
import { useForm, useReCaptcha } from '../../../../hooks'
import { Paths } from '../../../../constants/structure'

import { registerFields } from '../../../../common/utils/register'

import * as styles from './registerForm.module.scss'
import { RegisterFormProps, RegisterData } from './registerForm.type'
import { buildRegisterFormSchema } from './registerForm.schema'

const COMPANY_NAME = process.env.APP_COMPANY

const RegisterForm = ({
   data,
   isLoading,
   onRegister,
   showPasswordFields = true,
   submitLabel = 'Register',
}: RegisterFormProps) => {
   const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false)
   const { ReCAPTCHA, sitekey, recaptchaRef, size, disableRecaptcha } =
      useReCaptcha()
   const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)

   const handleCaptchaChange = () => {
      const token = recaptchaRef.current.getValue()
      setRecaptchaToken(token)
   }

   const { onSubmit, validationErrors } = useForm(
      buildRegisterFormSchema(showPasswordFields),
      (values: RegisterData) => {
         onRegister({ ...values, recaptchaToken })
      }
   )

   const handleToggle = (_id, status: boolean) => setAgreedToTerms(status)

   return (
      <form noValidate onSubmit={onSubmit}>
         <Input
            id={registerFields.COMPANY}
            label="Company Name"
            required
            value={data?.company}
            error={validationErrors[registerFields.COMPANY]}
         />

         <Row>
            <Col xs={6}>
               <Input
                  id={registerFields.FIRST_NAME}
                  label="First Name"
                  required
                  value={data?.firstName}
                  error={validationErrors[registerFields.FIRST_NAME]}
               />
            </Col>
            <Col xs={6}>
               <Input
                  id={registerFields.LAST_NAME}
                  label="Last Name"
                  required
                  value={data?.lastName}
                  error={validationErrors[registerFields.LAST_NAME]}
               />
            </Col>
         </Row>

         <Input
            id={registerFields.PHONE_NUMBER}
            label="Phone Number"
            required
            value={data?.phoneNumber}
            error={validationErrors[registerFields.PHONE_NUMBER]}
         />

         <Input
            id={registerFields.CITY}
            label="City"
            required
            value={data?.city}
            error={validationErrors[registerFields.CITY]}
         />

         <Input
            id={registerFields.STATE}
            label="State / Province"
            required
            value={data?.stateProvince}
            error={validationErrors[registerFields.STATE]}
         />

         <Input
            id={registerFields.COUNTRY}
            label="Country (optional)"
            value={data?.country}
         />

         {showPasswordFields && (
            <>
               <hr />
               <Input
                  id={registerFields.PASSWORD}
                  inputProps={{
                     type: 'password',
                     autoComplete: 'new-password',
                  }}
                  value=""
                  label="Password"
                  required
                  error={validationErrors[registerFields.PASSWORD]}
               />

               <Input
                  id={registerFields.CONFIRM_PASSWORD}
                  inputProps={{
                     type: 'password',
                     autoComplete: 'new-password',
                  }}
                  value=""
                  label="Confirm Password"
                  required
                  error={validationErrors[registerFields.CONFIRM_PASSWORD]}
               />

               <hr />
            </>
         )}

         <div className={styles.termsWrapper}>
            <div className={styles.termsToggle}>
               <Toggle
                  id={registerFields.TERMS}
                  size="md"
                  isChecked={agreedToTerms}
                  onToggle={handleToggle}
               />
            </div>
            <div className={styles.termsText}>
               Do you accept the {COMPANY_NAME} Terms & Conditions and accept
               our data{' '}
               <a href={Paths.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                  privacy policy
               </a>
               ? *
            </div>
            {!!validationErrors[registerFields.TERMS] && (
               <div className={styles.termsError}>
                  <FieldError text={validationErrors[registerFields.TERMS]} />
               </div>
            )}
         </div>

         <input
            type="hidden"
            id={registerFields.TERMS}
            name={registerFields.TERMS}
            value={agreedToTerms ? agreedToTerms.toString() : ''}
         />
         <div className={styles.recaptchaContainer}>
            {!disableRecaptcha && (
               <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={sitekey}
                  size={size}
                  onChange={handleCaptchaChange}
               />
            )}
         </div>
         <Button
            loading={isLoading}
            disabled={
               !disableRecaptcha
                  ? !agreedToTerms || !recaptchaToken
                  : !agreedToTerms
            }
         >
            {submitLabel}
         </Button>
      </form>
   )
}

export default RegisterForm
