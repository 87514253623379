import * as yup from 'yup'
import { ObjectSchema } from 'yup'

import { requiredError } from '../../../../common/utils/register'
import { registerFields } from '../../../../common/utils/register'

export const buildRegisterFormSchema = (
   hasPasswordFields?: boolean
): ObjectSchema<any> => {
   const passwordFields = hasPasswordFields
      ? {
           [registerFields.PASSWORD]: yup
              .string()
              .required(
                 'Your password requires at least 8 characters, minimum one upper case'
              ),
           [registerFields.CONFIRM_PASSWORD]: yup
              .string()
              .required(requiredError)
              .oneOf(
                 [yup.ref(registerFields.PASSWORD), null],
                 'The password and confirm password fields do not match'
              ),
        }
      : {}

   return yup.object().shape({
      [registerFields.COMPANY]: yup.string().required(requiredError),
      [registerFields.FIRST_NAME]: yup.string().required(requiredError),
      [registerFields.LAST_NAME]: yup.string().required(requiredError),
      [registerFields.PHONE_NUMBER]: yup.string().required(requiredError),
      [registerFields.CITY]: yup.string().required(requiredError),
      [registerFields.STATE]: yup.string().required(requiredError),
      [registerFields.COUNTRY]: yup.string(),
      [registerFields.TERMS]: yup
         .string()
         .required('You must accept the terms and conditions'),
      ...passwordFields,
   })
}
