import { FormState } from '../../context/registerContext/registerContext.type';
import { UserTrial } from './useAccountTrial.type';

export function prepareUserForAPI(formState: FormState): UserTrial {
    return {
        Email: formState.email,
        ConfirmEmail: formState.confirmEmail,
        FirstName: formState.firstName,
        LastName: formState.lastName,
        Company: formState.company,
        AcceptTerms: 'true',
        City: formState.city,
        State: formState.stateProvince,
        Password: formState.password,
        ConfirmPassword: formState.confirmPassword,
        IndustryID: '11',
        PhoneNumber: formState.phoneNumber,
        Country: formState.country,
        Token: formState.recaptchaToken
    }
}