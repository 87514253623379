import * as yup from 'yup'

const registerFields = {
   CITY: 'city',
   COMPANY: 'company',
   CONFIRM_PASSWORD: 'confirmPassword',
   COUNTRY: 'country',
   EMAIL: 'email',
   CONFIRM_EMAIL: 'confirmEmail',
   FIRST_NAME: 'firstName',
   LAST_NAME: 'lastName',
   PHONE_NUMBER: 'phoneNumber',
   PASSWORD: 'password',
   STATE: 'stateProvince',
   TERMS: 'terms',
}

const requiredError = 'This field is required'

const validPhoneNumberError = 'Please enter a valid phone number'

const step1Schema = yup.object().shape({
   [registerFields.EMAIL]: yup
      .string()
      .email('Your email does not appear to be valid')
      .required('Your email is required'),
})

const step2aSchema = yup.object().shape({
   [registerFields.PASSWORD]: yup.string().required(requiredError),
})

export {
   registerFields,
   requiredError,
   validPhoneNumberError,
   step1Schema,
   step2aSchema,
}
